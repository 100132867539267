import React, { useState } from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import Logo from "../images/CodeLoverTech_Logo.png";
// import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <motion.div>
        <div className="navBar fixed-top">
          <IconContext.Provider value={{ color: "#fff" }}>
            <div className="navbar">
              <Link to="#" className="menu-bars">
                <FaIcons.FaBars onClick={showSidebar} />
              </Link>
              <Link to="/" className="img_contain ">
                <img
                  src={Logo}
                  className="img-fluid "
                  width="140"
                  height="90"
                  alt="img"
                />
              </Link>
              {/* <div><h2 className="ms-5 mt-3">Admin Panel</h2>
            </div> */}
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
              <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle">
                  <Link
                    to="#"
                    className="menu-bars"
                    style={{ marginLeft: "10rem"}}
                  >
                    <AiIcons.AiOutlineClose />
                  </Link>
                </li>
                {SidebarData.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </IconContext.Provider>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
